import React, { useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./About.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import aris from "../../images/aris.png";
import book from "../../images/book.jpg";
import gall1 from "../../images/gall1.jpg";
import enJson from "../../languages/eng.json";
import grJson from "../../languages/gr.json";

const About = () => {
  const [show, setShow] = useState(false);
  const lang = localStorage.getItem("lang");

  const handleCloseAbout = () => setShow(false);
  const handleShowABout = () => setShow(true);

  return (
    <div className="body-about">
      <p className="title" style={{ fontSize: 54 }}>
        ΟΙ ΡΙΖΕΣ
      </p>

      <div className="about-Aris">
        <p className="txt-aris">
          {" "}
          {1 === 0 ? enJson.about.intro : grJson.about.intro}{" "}
        </p>
        <img className="img-aris" src={aris} alt="aris" />
      </div>

      <Button
        className="timeline-btn"
        variant="primary"
        onClick={handleShowABout}>
        {1 === 0 ? enJson.about.btn : grJson.about.btn}
      </Button>
      <br />
      <br />
      <br />

      <Modal size="xl" show={show} onHide={handleCloseAbout}>
        <Modal.Header closeButton>
          <Modal.Title>
            {1 === 0
              ? enJson.about["modal-title"]
              : grJson.about["modal-title"]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#fff", color: "#000" }}
              contentArrowStyle={{ borderRight: "7px solid  #fff" }}
              date="1980"
              iconStyle={{ background: "#5A4825", color: "#fff" }}>
              <p>{1 === 0 ? enJson.about.block1.p1 : grJson.about.block1.p1}</p>
              <p>{1 === 0 ? enJson.about.block1.p2 : grJson.about.block1.p2}</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#fff", color: "#000" }}
              contentArrowStyle={{ borderRight: "7px solid  #fff" }}
              date="1989"
              iconStyle={{ background: "#79653C", color: "#fff" }}>
              <p>{1 === 0 ? enJson.about.block2.p1 : grJson.about.block2.p1}</p>
              <p>
                {1 === 0 ? enJson.about.block2.p2 : grJson.about.block2.p2}{" "}
              </p>
              <img
                style={{
                  width: "130px",
                  height: "200px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={book}
                alt="the Book"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#fff", color: "#000" }}
              contentArrowStyle={{ borderRight: "7px solid  #fff" }}
              date="1993"
              iconStyle={{ background: "#917D54", color: "#fff" }}>
              <p>{1 === 0 ? enJson.about.block3.p1 : grJson.about.block3.p1}</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#fff", color: "#000" }}
              contentArrowStyle={{ borderRight: "7px solid  #fff" }}
              date="1994"
              iconStyle={{ background: "#AC9973", color: "#fff" }}>
              <p>{1 === 0 ? enJson.about.block4.p1 : grJson.about.block4.p1}</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#fff", color: "#000" }}
              contentArrowStyle={{ borderRight: "7px solid  #fff" }}
              date="Now..."
              iconStyle={{ background: "#CDBB97", color: "#fff" }}>
              <p>{1 === 0 ? enJson.about.block5.p1 : grJson.about.block5.p1}</p>
              <p>{1 === 0 ? enJson.about.block5.p2 : grJson.about.block5.p2}</p>
              <p>{1 === 0 ? enJson.about.block5.p3 : grJson.about.block5.p3}</p>
            </VerticalTimelineElement>
          </VerticalTimeline>

          <img
            style={{
              width: "300px",
              height: "500px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={gall1}
            alt="the Book"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAbout}>
            Κλείσιμο
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default About;
