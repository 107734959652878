import React, { useEffect } from "react";

import "./App.css";
import CTA from "../cta";
import About from "../about";
import Services from "../services";
import Testimonials from "../testimonials";
import Contact from "../contact";
import Surnames from "../surnames";
import Steps from "../Steps/Steps";
import eng from "../../languages/eng.json";
import gr from "../../languages/gr.json";

function App() {
  const id = localStorage.getItem("id");
  const lang = localStorage.getItem("lang");

  useEffect(() => {}, [id, lang]);

  return (
    <div className="body">
      <br />

      <div>
        <About />
        <Services />
        <Surnames />
        <Testimonials />
      </div>

      <Contact />

      <br />
    </div>
  );
}

export default App;
