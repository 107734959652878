import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import "./Services.css";
import Examples from "../examples/Examples";
import research from "../../images/research.png";
import enJson from "../../languages/eng.json";
import grJson from "../../languages/gr.json";

const Services = () => {
  const [show, setShow] = useState(false);
  const lang = localStorage.getItem("lang");

  const handleCloseAbout = () => setShow(false);
  const handleShowABout = () => setShow(true);

  return (
    <div className="body">
      <p className="title">
        {1 === 0 ? enJson.services.title : grJson.services.title}
      </p>

      <div className="about-methodology">
        <img className="img-methodology" src={research} alt="aris" />
        <p className="txt-methodology">
          {" "}
          {1 === 0 ? enJson.services.p1 : grJson.services.p1}
        </p>
      </div>

      <Button
        style={{ width: "200px" }}
        className="examples-btn"
        variant="primary"
        onClick={handleShowABout}>
        {1 === 0 ? enJson.services.btn : grJson.services.btn}
      </Button>
      <br />
      <br />
      <br />

      <Modal size="xl" show={show} onHide={handleCloseAbout}>
        <Modal.Body>
          <Examples />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAbout}>
            {1 === 0
              ? enJson.services.examples.btnClose
              : grJson.services.examples.btnClose}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Services;
