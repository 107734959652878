import React from "react";
import "./Examples.css";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import example1 from "../../images/example1.jpg";
import example2 from "../../images/example2.jpg";
import example3 from "../../images/example3.png";

const Examples = () => {
  return (
    <div className="body-examples">
      <Carousel>
        <Carousel.Item>
          <img
            className="examples"
            style={{ width: "800px", height: "700px" }}
            src={example1}
            alt="ex2"
          />
        </Carousel.Item>{" "}
        <Carousel.Item>
          <img
            className="examples"
            style={{ width: "800px", height: "700px" }}
            src={example2}
            alt="ex2"
          />
        </Carousel.Item>{" "}
        <Carousel.Item>
          <h3>Aλλαγές επωνύμου</h3>
          <img
            className="examples"
            style={{ width: "500px", height: "700px" }}
            src={example3}
            alt="ex3"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Examples;
