import React, { useState, useEffect } from "react";
import "./Surnames.css";
import { list } from "./list";
import enJson from "../../languages/eng.json";
import grJson from "../../languages/gr.json";

const Surnames = () => {
  const lang = localStorage.getItem("lang");

  const [surnames, setSurnames] = useState([]);
  const [filtered, setFiltered] = useState("");

  useEffect(() => {
    setSurnames(list.map((item) => item.name));
  }, []);

  useEffect(() => {}, [surnames]);

  const handleChange = ({ target: { value } }) => {
    setFiltered(surnames.filter((surname) => surname.includes(value)));
  };

  return (
    <div className="body">
      <h2 className="title">
        {1 === 0 ? enJson.surnames.title : grJson.surnames.title}
      </h2>
      <form>
        <p style={{ color: "black", fontSize: "22px" }}>
          {1 === 0 ? enJson.surnames.p1 : grJson.surnames.p1}
        </p>
        <input
          className="search-surnames"
          type="text"
          placeholder={
            1 === 0 ? enJson.surnames.placeHolder : grJson.surnames.placeHolder
          }
          onChange={handleChange}
        />
        <br />
        {filtered && <textarea className="textarea" defaultValue={filtered} />}
      </form>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Surnames;
