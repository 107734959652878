import React from "react";
import "./Testimonials.css";
import enJson from "../../languages/eng.json";
import grJson from "../../languages/gr.json";

const Testimonials = () => {
  const lang = localStorage.getItem("lang");
  return (
    <div className="body">
      <p className="title">
        {1 === 0 ? enJson.testimonials.title : grJson.testimonials.title}
      </p>

      <div className="test">
        <div className="test1">
          <p className="test-title">Jimmy Weeks</p>
          <p className="test-body">
            {" "}
            {1 === 0
              ? enJson.testimonials.test1.p1
              : grJson.testimonials.test1.p1}
          </p>

          <p className="test-body">
            {1 === 0
              ? enJson.testimonials.test1.p2
              : grJson.testimonials.test1.p2}
          </p>
          <p className="test-foot">
            {" "}
            {1 === 0
              ? enJson.testimonials.test1.review
              : grJson.testimonials.test1.review}
          </p>
        </div>

        <div className="test2">
          <p className="test-title">
            {1 === 0
              ? enJson.testimonials.test2.name
              : grJson.testimonials.test2.name}
          </p>
          <p className="test-body">
            {1 === 0
              ? enJson.testimonials.test2.p1
              : grJson.testimonials.test2.p1}
          </p>
          <br />
          <p className="test-body">
            {1 === 0
              ? enJson.testimonials.test2.p2
              : grJson.testimonials.test2.p2}
          </p>
          <p className="test-foot">
            {" "}
            {1 === 0
              ? enJson.testimonials.test2.review
              : grJson.testimonials.test2.review}
          </p>
          <br />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
