import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Contact.css";
import enJson from "../../languages/eng.json";
import grJson from "../../languages/gr.json";

const Contact = () => {
  const [input, setInput] = useState({
    name: "",
    from: "",
    subject: "",
    text: "",
  });

  const lang = localStorage.getItem("lang");

  const inputChange = ({ target: { name, value } }) => {
    setInput({ ...input, [name]: value });
  };

  // const sendMail = () => {
  //   axios
  //     .post(
  //       `https://europe-west1-droopy-f0792.cloudfunctions.net/api/mail`,
  //       input,
  //       {
  //         "content-type": "application/json",
  //       }
  //     )
  //     .then((res) => {
  //       console.log("res", res.data);
  //       alert("Email sent! Thank you!");
  //     })
  //     .catch((e) => {
  //       alert("Can not send the email! Try again!");
  //       console.log("err", e);
  //     });
  // };

  return (
    <div>
      <h2 className="title" style={{ marginTop: "10%" }}>
        {1 === 0 ? enJson.contact.title : grJson.contact.title}
      </h2>
      <Form className="contact">
        {/* <Form.Group controlId="Name">
          <Form.Label className="label">
            {1 === 0 ? enJson.contact.name : grJson.contact.name}
          </Form.Label>
          <Form.Control
            as="input"
            name="name"
            value={input.name}
            type="text"
            placeholder={
              lang === "eng"
                ? enJson.contact.placeHolderName
                : grJson.contact.placeHolderName
            }
            onChange={inputChange}
          />
        </Form.Group>

        <Form.Group controlId="Subject">
          <Form.Label className="label">
            {1 === 0 ? enJson.contact.subject : grJson.contact.subject}
          </Form.Label>
          <Form.Control
            as="input"
            name="subject"
            value={input.subject}
            type="text"
            placeholder={
              lang === "eng"
                ? enJson.contact.placeHolderSubject
                : grJson.contact.placeHolderSubject
            }
            onChange={inputChange}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label className="label">
            {1 === 0 ? enJson.contact.email : grJson.contact.email}
          </Form.Label>
          <Form.Control
            as="input"
            name="from"
            value={input.from}
            type="email"
            placeholder={
              lang === "eng"
                ? enJson.contact.placeHolderEmail
                : grJson.contact.placeHolderEmail
            }
            onChange={inputChange}
          />
        </Form.Group>

        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label className="label">
            {1 === 0 ? enJson.contact.textArea : grJson.contact.textArea}
          </Form.Label>
          <Form.Control
            as="textarea"
            name="text"
            value={input.text}
            type="text"
            placeholder={
              lang === "eng"
                ? enJson.contact.placeHolderTextArea
                : grJson.contact.placeHolderTextArea
            }
            rows="5"
            onChange={inputChange}
          />
        </Form.Group>

        <Button className="btn" variant="primary" onClick={sendMail}>
          {1 === 0 ? enJson.contact.btn : grJson.contact.btn}
        </Button> */}
        <a
          style={{ fontSize: 18 }}
          href="https://www.facebook.com/aris.poulimenakos"
          target="_blank">
          Βρείτε μας στο Facebook
        </a>
        <br />
        <span style={{ color: "#000" }}>ή</span>
        <br />
        <a style={{ fontSize: 18 }} href="mailto:oirizes@otenet.gr">
          Μέσω e-mail: oirizes@otenet.gr
        </a>
      </Form>
    </div>
  );
};

export default Contact;
